<template>
  <div class="about section-vh">
    <b-container>
      <b-row>
        <b-col class="col my-5">
          <div class="text-left text-white" v-if="$i18n.locale === 'ru'">
            <div class="h5 text-left fifa-text-color border_title pb-4 mb-4">Система проведения соревнований – регулярные Head-2-Head Liga - турниры по интерактивному футболу</div>
            <p>
              <b class="text-left fifa-text-color">Продолжительность турнира составляет не более одного дня.</b>
            </p>
            <p>
              Турнир состоит из 12 встреч: 12 встреч группового этапа, круговая система (round-robin) в два круга. Игра ведется без дополнительного времени и пенальти. Количество участников турнира – 4 человека.
            </p>
            <p>
              <b class="text-left fifa-text-color">Турнир проводится в один этап:</b><br>
            </p>
            <p>
              Групповой этап. Встречи группового этапа проводятся только в основное время без экстра-таймов и пенальти. В матче может быть зафиксирована ничья.
            </p>
            <p>
              Все участники играют в два круга между собой (у каждого из участников по 6 встреч), всего 12 встреч в группе. Победитель выявляется на основании лучших показателей – набранных очков.
            </p>
            <p>
              В ходе проведения группового этапа турнира команды набирают игровые очки, которые по итогам каждой встречи турнира складываются. Итоговое место участника в групповом этапе определяется по сумме набранных очков.
            </p>
            <p>
              За победу во встрече участнику начисляются 3 очка, за ничью – 1 очко, проигравшему игроку очки не начисляются.
            </p>
            <p>
              <b class="text-left fifa-text-color">В случае если одинаковое количество очков было набрано двумя участниками, победитель определяется согласно правилам, указанным ниже (по&nbsp;ранжированию):</b>
            </p>
            <p>
              - на основании результатов их личной встречи;<br>
              - при ничьей в личной встрече определение победителя производится по разнице забитых и пропущенных мячей;<br>
              - по большему количеству забитых мячей;<br>
              - по жребию.
            </p>
            <p>
              <b class="text-left fifa-text-color">В случае если одинаковое количество очков было набрано тремя и более участниками, победитель определяется согласно правилам, указанным ниже (по&nbsp;ранжированию):</b>
            </p>
            <p>
              - по количеству очков, набранных участниками в играх между собой;<br>
              - по разнице забитых и пропущенных мячей в играх между собой;<br>
              - по большему количеству забитых мячей во встречах между собой;<br>
              - по разнице забитых и пропущенных мячей в проведенных встречах со всеми соперниками;<br>
              - по большему количеству забитых мячей в проведенных встречах со всеми соперниками;<br>
              - по жребию.
            </p>
            <p>
              По окончании группового этапа производится подсчет очков, по итогам которого выявляется победитель турнира.
            </p>
          </div>

          <div class="text-left text-white" v-else>
            <div class="h5 text-left fifa-text-color border_title pb-4 mb-4">Competition system: regular Head-2-Head Liga interactive football tournaments</div>
            <p>
              <b class="text-left fifa-text-color">Tournament duration is no longer than one day.</b>
            </p>
            <p>
              The tournament consists of 12 matches: 12 group stage matches, a two-group round-robin. The game is played without extra time or penalties. Number of tournament participants: 4 people.
            </p>
            <p>
              <b class="text-left fifa-text-color">The tournament is held in one stage:</b><br>
            </p>
            <p>
              Group stage. Group stage matches are held only with regular time, without extra time or penalties. Matches may end in a draw.
            </p>
            <p>
              All participants play each other in two groups (each participant has 6 meetings), with a total of 12 meetings in a group. The winner is determined on the basis of the number of points scored.
            </p>
            <p>
              During the group stage of the tournament, the teams earn game points that are added up at the end of each tournament match. The final place of the participant in the group stage is determined by the sum of points earned.
            </p>
            <p>
              A participant is awarded 3 points for winning a match and 1 point for a draw, and the losing player is not awarded any points.
            </p>
            <p>
              <b class="text-left fifa-text-color">In the event that the same number of points was earned by two participants, the winner is determined based on the rules indicated below (by&nbsp;ranking):</b>
            </p>
            <p>
              - based on the results of their H2H match;<br>
              - if the H2H match was a draw, the winner is determined by the difference between goals scored and goals conceded;<br>
              - based on who scored the most goals;<br>
              - by a coin toss.
            </p>
            <p>
              <b class="text-left fifa-text-color">In the event that the same number of points was earned by three or more participants, the winner is determined based on the rules indicated below (by&nbsp;ranking):</b>
            </p>
            <p>
              - by the number of points scored by the participants in games against each other;<br>
              - by the difference between goals scored and conceded in games against each other;<br>
              - by who scored more goals in games against each other;<br>
              - by the difference of goals scored and conceded in games against all other opponents;<br>
              - by who scored the most goals in games against all opponents;<br>
              - by a toss of a coin.
            </p>
            <p>
              At the end of the group stage, points are calculated and the winner of the tournament is announced.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "efootball_rules",
  data() {
      return {}
  },
  metaInfo () {
      return {
          title: this.$t('meta.sports.fifa.rules.title'),
          titleTemplate: 'H2H Liga | %s',
          meta: [
              {name: 'description', content: this.$t('meta.sports.fifa.rules.description')},
          ]
      }
  }
}
</script>

<style scoped>
.border_title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>